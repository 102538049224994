import "../scss/index.scss";
import "./prism.js";

(window as any).kbase = {
    tree: {
        toggleGroupOpened: function (id: string) {
            let el = document.getElementById(`tree-group-${id}`);
            if (el) {
                el.classList.toggle('opened');
            }
        }
    }
}